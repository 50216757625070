@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, #root, .class-navugation-block, .cn-body, .container, .main-container, .class-navugation-block, .content-block {
    height: 100%;
    min-height: 100%;
  }

  .main-container {
    display: flex;
    flex-direction: column;
  }

  .container {
    padding-bottom: 10px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .content-block {
    padding: 0;
  }

  .class-navugation-block {
      color: white;
      padding: 0 !important;
  } 

  .c-header {
    height: 50px;
    margin: 0 !important;
  }

  .c-main {
    height: 100%;
    margin: 0px;
    background-color: white;
  }

  /* .cn-title {
    height: 50px;
    align-items: center;
    text-align: center;
    font-size: 0.92em;
    font-weight: 600;
    background-color: #7278ef;
  } */

  .main-container {
    border: 1px solid #e5e3f0;
    border-top: none;
  }

  /* .cn-title span {
    float: left;
    margin-left: 0.4em;
  } */

  .ch-left {
    justify-content: flex-end;
    align-items: center;
  }

  .ch-right {
    justify-content: flex-start;
    align-items: center;
  }

  .cn-loading {
    margin: 0.7em;
  }

  .cn-body {
    height: calc(100% - 50px);
    max-height: calc(100% - 50px);
    overflow-y: scroll;
    background-color: #333452;
  }


  .btn-custom {
    background-color: transparent;
    color: black;
    border: none;
  }

  .big-divider {
    margin: 0;
    background-color: #f3f3fa;
    height: 15px;
  }


  .aa {
    flex-direction: row;
    position: relative;
    padding-right: 7px;
    display: flex;
    align-items: center;
  }

  .ab {
    font-size: 9px;
    position: absolute;
    top: -4px;
    /* left: 68px; */
    right: -10px;
    text-align: center;
    padding: 0px 4px;
    background-color: #7278ef;
    border-radius: 7px;
    min-width: 14px;
  }
  .cd {
    top: -8px !important;
    left: 32px;
  }

  .cdd {
    top: -8px !important;
    left: 4px;
  }

  .ac {
    margin-right: 5px;
  }

  .ae {
    text-align: center;
    font-size: 9px;
    color: #9e9d9d;
    margin-right: 3px;
    min-width: 25px;
  }

  .ef {
    margin-right: 18px;
  }

  .ty {
    width: 32px;
    height: 23px;
    margin-left:5px;
  }

  .breadcrumb-item.active, .breadcrumb-item a {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breadcrumb-item a {
    color: white;
  }
